// This file extends the Bootstrap modal by looking for modals with the `blur` class added.
// When a modal with the `blur` class is opened, it will add a `modal-backdrop-blur` class to the backdrop.

// This function looks for the modal backdrop and returns a promise that resolves when it finds it.
// It checks every 20ms for the backdrop to be added to the DOM.
// This is needed because the backdrop is added to the DOM AFTER the `show.bs.modal` event is fired.
const waitForModalBackdrop = () => {
  return new Promise(resolve => {
    const interval = setInterval(() => {
      const backdrop = document.querySelector('.modal-backdrop')
      if (backdrop) {
        clearInterval(interval)
        resolve(backdrop)
      }
    }, 20)
  })
}

const handleModalShow = async () => {
  const backDrop = await waitForModalBackdrop()
  backDrop.classList.add('modal-backdrop-blur')
}

const addEventListener = (element, event, callback) => {
  if (typeof $ !== 'undefined') {
    // Use JQuery if it is defined, for some reason this is needed for Bootstrap 3 compatibility.
    /* eslint-disable-next-line no-undef */
    $(element).on(event, callback)
  } else {
    element.addEventListener(event, callback)
  }
}

document.querySelectorAll('.modal.blur').forEach(modal => {
  // We listen for the `show.bs.modal` event instead of the `shown.bs.modal` event.
  // This is because the `shown.bs.modal` event is fired AFTER the backdrop is added to the DOM.
  // Meaning the user sees the backdrop without the blur effect for a second.
  addEventListener(modal, 'show.bs.modal', handleModalShow)
})
